<template>
  <div>
    <q-legend
      :label="$t('General')"
      class="q-mb-sm"
    />

    <form-builder :schema="schema" />

    <q-legend
      :label="$t('Conditions')"
      class="q-my-sm"
    />

    <filter-collapse
      :is-open="true"
      :options="{
        changeEvent: true,
        defaultFilter: model.conditions || [],
        fields: fields,
        values: values,
        style: {
          noButtons: true,
          flat: true,
          noGroups: true
        }
      }"
      @filter-change="handleConditions"
    />

    <q-legend
      :label="$t('Template')"
      class="q-my-sm"
    />

    <material-editor
      :keywords="keywords"
      :model-value="model.template"
      :style-options="{ onlyKeywords: type !== 'email' }"
      style="min-height: 300px;"
      @update:model-value="handleTemplate"
    />
  </div>
</template>

<script>
// Components
import MaterialEditor from '../editor/MaterialEditor.vue'
import FilterCollapse from '../filters/FilterCollapse.vue'

export default {
  name: 'NotificationTaskPanel',
  components: {
    FilterCollapse,
    MaterialEditor
  },
  props: {
    type: {
      type: String,
      default () {
        return 'sms'
      }
    },
    values: {
      type: Object,
      default () {
        return {}
      }
    },
    keywords: {
      type: Array,
      default () {
        return []
      }
    },
    fields: {
      type: Array,
      default () {
        return []
      }
    },
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    model: {
      type: Object,
      required: true,
      default () {
        return null
      }
    }
  },
  computed: {
    schema () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'input',
                inputType: 'text',
                label: this.$t('Name'),
                field: 'name',
                value: this.model.name,
                required: true,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                onChange: name => {
                  this.handleChange({ name })
                }
              },
              {
                type: 'input',
                inputType: 'text',
                label: this.$t('Delay'),
                field: 'delay',
                value: this.model.delay,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                onChange: delay => {
                  this.handleChange({ delay })
                }
              }
            ]
          }
        ]
      }
    }
  },
  methods: {
    handleTemplate (template) {
      return this.handleChange({ template })
    },
    handleConditions (conditions) {
      return this.handleChange({ conditions })
    },
    handleChange (update) {
      const model = {
        ...this.model,
        ...update
      }

      this.$emit('change', model)
      return model
    }
  }
}
</script>
