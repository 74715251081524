<template>
  <q-card class="q-ma-sm">
    <q-tabs
      :model-value="tab"
      dense
      align="justify"
      class="tab-list"
    >
      <q-tab
        v-for="task in activeTasks"
        :key="task.id"
        class="col"
        :disable="disabled || isLoading > 0"
        :name="task.id"
        :label="$t(getTabName(task.event))"
        no-caps
        @click="handleTabChange(task)"
      >
        <task-badge :model="task" />
      </q-tab>
    </q-tabs>

    <q-separator />

    <div
      v-if="isLoading > 0"
      class="row items-center justify-center"
      style="min-height: 200px;"
    >
      <q-spinner
        color="primary"
        size="4rem"
        class="q-mb-md"
      />
    </div>

    <div
      v-if="isLoading <= 0 && activeTasks.length <= 0"
      class="row items-center justify-center text-subtitle1"
      style="min-height: 200px;"
    >
      {{ $t(`Please add event via 'Events' button!`) }}
    </div>

    <q-tab-panels
      v-model="tab"
      animated
      keep-alive
    >
      <q-tab-panel
        v-for="task in activeTasks"
        :key="task.id"
        :name="task.id"
      >
        <notification-task-panel
          :model="task"
          :disabled="isLoading > 0 || disabled"
          :fields="fields[task.event] || fields.default"
          :values="values[task.event] || values.default"
          :keywords="keywords[task.event] || keywords.default"
          :type="channel.type"
          @change="handleTaskChange"
        />

        <div class="text-right q-pa-xs">
          <q-btn
            color="negative"
            :label="$t('Delete')"
            no-caps
            @click="deleteTask(task.id)"
          />
        </div>
      </q-tab-panel>
    </q-tab-panels>

    <portal to="channel-header">
      <action-lazy-dropdown :option="dropdownSchema" />
    </portal>
  </q-card>
</template>

<script>
// Utils
import _ from 'lodash'
import { convertEmbedded, difference } from '../../helpers/request-helpers'

// Components
import NotificationTaskPanel from './NotificationTaskPanel.vue'
import ActionLazyDropdown from '../action-header/ActionLazyDropdown.vue'
import TaskBadge from '../global/TaskBadge.vue'

// Configs
import OrderMatrix from '../../config/OrderMatrix'
import DeliveryRequestMatrix from '../../config/DeliveryRequestMatrix'

export default {
  name: 'NotificationPanel',
  components: {
    NotificationTaskPanel,
    ActionLazyDropdown,
    TaskBadge,
    // NotificationTemplatePanel
  },
  props: {
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    channel: {
      type: Object,
      required: true,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      keywords: {
        default: [],
        'products.orders.saved:order': [
          { id: 'id', name: 'ID' },
          { id: 'extId', name: 'ExtId' },
          { id: 'deliveryRequest.trackingNumber', name: 'Tracking number' },
          { id: 'deliveryRequest.deliveryService.name', name: 'Delivery Carrier' }
        ],
        'delivery.services.request.saved:deliveryRequest': [
          { id: 'id', name: 'ID' },
          { id: 'extId', name: 'ExtId' },
          { id: 'trackingNumber', name: 'Tracking number' },
          { id: 'deliveryService.name', name: 'Delivery Carrier' },
          { id: 'servicePoint.rawAddress', name: 'Pick up point address' },
          { id: 'recipientAddress.postcode', name: 'Postcode' },
          { id: 'payment', name: 'Payment' }
        ]
      },
      values: {
        default: {},
        'storage.movements.acceptance.saved:acceptance': {
          types: [
            { id: 'products', title: 'Products' },
            { id: 'items', title: 'Items' },
            { id: 'palletized', title: 'Palletized' }
          ],
          states: [
            { id: 'checking', title: 'Checking' },
            { id: 'new', title: 'New' },
            { id: 'confirmed', title: 'Confirmed' }
          ]
        },
        'products.orders.saved:order': {
          states: OrderMatrix
        },
        'delivery.services.request.saved:deliveryRequest': {
          states: DeliveryRequestMatrix
        },
        'products.orders.reserve.saved:reserve': {
          states: [
            { id: 'new', title: 'New' },
            { id: 'confirmed', title: 'Confirmed' },
            { id: 'queued', title: 'Queued' },
            { id: 'processing', title: 'Processing' },
            { id: 'complete', title: 'Complete' },
            { id: 'cancelled', title: 'Cancelled' },
            { id: 'sorting', title: 'Sorting' },
            { id: 'distribution', title: 'Distribution' },
            { id: 'packaging', title: 'Packaging' },
          ]
        },
        'storage.item.save.post:item': {},
      },
      fields: {
        'products.orders.reserve.saved:reserve': ['warehouse', 'shop', 'state'],
        'storage.item.save.post:item': ['shop', 'warehouse'],
        'products.orders.saved:order': ['id', 'extId', 'clientId', 'profile', 'phone', 'shipmentDate.from', 'shipmentDate.to', 'created.from', 'created.to', 'deliveryService', 'paymentState', 'state', 'shop', 'source', 'deliveryRequest'],
        'delivery.services.request.saved:deliveryRequest': ['id', 'extId', 'clientExtId', 'trackingNumber', 'shippedByDocument', 'createdByDocument', 'recipient', 'recipientPhone', 'weight.from', 'weight.to', 'retailPrice.from', 'retailPrice.to', 'estimatedCost.from', 'estimatedCost.to', 'payment.from', 'payment.to', 'shipmentDate.from', 'shipmentDate.to', 'deliveryDate.from', 'deliveryDate.to', 'pickUpDate.from', 'pickUpDate.to', 'created.from', 'created.to', 'deliveryService', 'rate', 'servicePoint', 'integration', 'paymentState', 'state', 'sender'],
        'storage.movements.acceptance.saved:acceptance': ['id', 'extId', 'state', 'type', 'warehouse'],
        default: []
      },
      isLoading: 0,
      tab: '',
      task: null,
      tasks: [],
      originalTasks: [],
      templates: [],
      originalTemplates: [],
      events: [
        { id: 'products.orders.reserve.saved:reserve', name: 'Reserve was changed' },
        { id: 'storage.item.save.post:item', name: 'Storage item was created' },
        { id: 'products.orders.saved:order', name: 'Order was changed' },
        { id: 'delivery.services.request.saved:deliveryRequest', name: 'Shipping request was changed' },
        { id: 'storage.movements.acceptance.saved:acceptance', name: 'PO was changed' }
      ]
    }
  },
  computed: {
    activeTasks () {
      return this.tasks.filter(x => x.state === 'active')
    },
    dropdownSchema () {
      return {
        id: 'events',
        type: 'lazy-dropdown',
        label: this.$t('Events'),
        wrapperStyle: 'max-width: 120px;',
        disabled: this.isLoading > 0 || this.disabled,
        noAutoclose: true,
        options: this.events,
        customIcon: () => {
          return {
            name: 'add',
            color: 'positive'
          }
        },
        customLabel: (item) => {
          if (item && typeof item === 'object') {
            return item.name
          }

          return item
        },
        onClick: (option) => {
          this.setNewTask(option.id)
        }
      }
    }
  },
  mounted () {
    if (!this.channel.id) {
      return
    }

    this.loadTasks(this.channel.id)
  },
  methods: {
    getTasks () {
      return this.tasks
    },
    getTabName (event) {
      return (this.events.find(x => x.id === event) || { name: event }).name
    },
    handleTabChange (task) {
      this.tab = task.id
      this.task = task

      // if (isNaN(Number(task.template))) {
      //   return Promise.resolve(null)
      // }

      // if (task.template) {
      //   const template = this.templates.find(x => x.id == task.template)

      //   if (template) {
      //     return Promise.resolve(template)
      //   }

      //   return this.loadTemplate(task.template)
      // }

      // return this.setNewTemplate(task)
    },
    deleteTask (id) {
      if (!this.channel.id) {
        this.tasks = this.tasks.filter(x => x.id != id)
      } else {
        this.tasks = this.tasks.map(x => {
          return x.id == id
            ? { ...x, state: 'inactive' }
            : x
        })
      }

      return this.tasks
    },
    deleteTaskByEvent (event) {
      if (!this.channel.id) {
        this.tasks = this.tasks.filter(x => x.event !== event)
      } else {
        this.tasks = this.tasks.map(x => {
          return x.event === event
            ? { ...x, state: 'inactive' }
            : x
        })
      }

      return this.tasks
    },
    loadTasks (value) {
      const query = {
        per_page: 250,
        page: 1,
        filter: [
          { type: 'eq', field: 'state', value: 'active' },
          { type: 'eq', field: 'channel', value }
        ]
      }

      this.isLoading++

      return this.$service.notificationTask.getAll(query)
        .then(data => {
          data.items = data.items.map(x => {
            if (!Array.isArray(x.conditions)) {
              x.conditions = []
            }
            if (this.channel.type !== 'email') {
              x.template = this.$utils.convertTextToHTML(x.template || '', /\{{(.*?)\}}/g, true)
            }

            return x
          })

          return data
        })
        .then(data => {
          this.tasks = data.items
          this.originalTasks = _.cloneDeep(data.items)

          if (this.tasks.length > 0) {
            this.handleTabChange(this.tasks[0])
          }

          return data
        })
        .finally(() => {
          this.isLoading--
        })
    },
    loadTemplate (id) {
      this.isLoading++
      return this.$service.notificationTemplate.get(id)
        .then(template => {
          this.templates.push(template)
          this.originalTemplates.push(_.cloneDeep(template))
          return template
        })
        .finally(() => {
          this.isLoading--
        })
    },
    setNewTemplate (task) {
      const template = {
        id: Math.floor(Math.random() * (9999999999) + 1),
        name: null,
        state: 'active',
        event: null,
        template: null
      }

      this.templates.push(template)
      task.template = template.id

      return Promise.resolve(template)
    },
    setNewTask (event) {
      const task = {
        id: Math.floor(Math.random() * (9999999999) + 1),
        delay: null,
        event,
        name: '',
        conditions: [],
        state: 'active',
        template: '',
        title: null,
        type: 'automatic'
      }

      this.tasks.push(task)
      this.handleTabChange(task)
      return task
    },
    handleTaskChange (task) {
      this.tasks = this.tasks.map(x => {
        return x.id == task.id
          ? task
          : x
      })

      this.$emit('update', { type: 'tasks', items: this.tasks })

      return this.tasks
    },
    handleTemplateChange (template) {
      this.templates = this.templates.map(x => {
        return x.id == template.id
          ? template
          : x
      })

      this.$emit('update', { type: 'templates', items: this.templates })

      return this.templates
    },
    save () {
      this.isLoading++

      // return this.saveTemplates(this.templates, this.originalTemplates, [])
      //   .then(() => {
      //     return this.saveTasks(this.tasks, this.originalTasks, [])
      //   })
      //   .finally(() => {
      //     this.isLoading--
      //   })

      return this.saveTasks(this.tasks, this.originalTasks, [])
        .then(results => {
          this.tasks = this.tasks.filter(x => x.state === 'active')
          this.originalTasks = _.cloneDeep(this.tasks)
          return results
        })
        .finally(() => {
          this.isLoading--
        })
    },
    saveTasks (tasks, orTasks, results = []) {
      if (tasks.length <= 0) {
        return Promise.resolve(results)
      }

      const task = tasks[0]
      const cleanTask = orTasks.find(x => x.id == task.id)

      return this.saveTask(task, cleanTask)
        .then(result => {
          if (result) {
            results.push(result)
          }

          return this.saveTasks(tasks.slice(1), orTasks, results)
        })
    },
    saveTask (task, cleanTask) {
      if (!cleanTask) {
        const data = {
          ...task,
          channel: this.channel.id,
          id: undefined
        }

        if (this.channel.type !== 'email') {
          data.template = this.$utils.decodeHTMLToText(data.template, true)
        }

        return this.$service.notificationTask.save(data)
          .then(result => {
            if (this.channel.type !== 'email') {
              result.template = this.$utils.convertTextToHTML(result.template || '', /\{{(.*?)\}}/g, true)
            }

            this.tasks = this.tasks.map(x => {
              return x.id == task.id
                ? result
                : x
            })

            this.originalTasks.push(_.cloneDeep(result))

            return result
          })
      }

      const diff = convertEmbedded(difference(task, cleanTask))

      if (Object.keys(diff).length <= 0) {
        return Promise.resolve(null)
      }

      // Conditions is array and change detection will recognized only changes in it
      // But we need to pass the whole array
      if (diff.conditions) {
        diff.conditions = task.conditions
      }

      if (this.channel.type !== 'email' && diff.template) {
        diff.template = this.$utils.decodeHTMLToText(diff.template, true)
      }

      return this.$service.notificationTask.save(diff, task.id)
        .then(result => {
          if (this.channel.type !== 'email') {
            result.template = this.$utils.convertTextToHTML(result.template || '', /\{{(.*?)\}}/g, true)
          }

          this.originalTasks = this.originalTasks.map(x => {
            return x.id == result.id
              ? result
              : x
          })

          return result
        })
    },
    saveTemplates (templates, orTemplates, results = []) {
      if (templates.length <= 0) {
        return Promise.resolve(results)
      }

      return this.saveTemplate(templates[0], orTemplates.find(x => x.id == templates[0].id))
        .then(result => {
          if (result) {
            results.push(result)
          }

          return this.saveTemplates(templates.slice(1), orTemplates, results)
        })
    },
    saveTemplate (template, cleanTemplate) {
      if (!cleanTemplate) {
        const data = {
          ...template,
          id: undefined
        }

        return this.$service.notificationTemplate.save(data)
          .then(result => {
            this.templates = this.templates.map(x => {
              return x.id == template.id
                ? result
                : x
            })

            this.originalTemplates.push(_.cloneDeep(result))

            this.tasks = this.tasks.map(x => {
              return x.template === template.id
                ? { ...x, template: result.id }
                : x
            })

            return result
          })
      }

      const diff = convertEmbedded(difference(template, cleanTemplate))

      if (Object.keys(diff).length <= 0) {
        return Promise.resolve(null)
      }

      return this.$service.notificationTemplate.save(diff, template.id)
        .then(result => {
          this.originalTemplates = this.originalTemplates.map(x => {
            return x.id == result.id
              ? result
              : x
          })

          return result
        })
    }
  }
}
</script>
