<template>
  <q-badge
    v-if="errors.length > 0"
    color="red"
    :floating="true"
  >
    {{ errors.length }}

    <q-tooltip>
      <div
        v-for="(err, i) in errors"
        :key="i"
        class="text-caption"
      >
        <q-item-section>
          {{ err }}
        </q-item-section>
      </div>
    </q-tooltip>
  </q-badge>
</template>

<script>
export default {
  name: 'TaskBadge',
  props: {
    model: {
      type: Object,
      default () {
        return null
      }
    }
  },
  computed: {
    errors () {
      const errors = []

      if (!this.model.name) {
        errors.push(`Please fill the name field.`)
      }

      if (!this.model.template) {
        errors.push(`Please fill the template field.`)
      }

      return errors
    }
  }
}
</script>
